<template>
  <div class="modal-overlay">
    <div class="modal">
      <slot name="header">
        <div class="modal-header">
          <span class="modal-title">课题查询</span>
          <button @click="closeModal" class="modal-close">X</button>
        </div>
      </slot>

      <div class="modal-body">
        <div class="search-container">
          <div class="form-item">
            <div class="label">课题ID: <span class="required"></span></div>
            <input
              type="text"
              placeholder="请输入课题ID"
              v-model="searchValue"
            />
          </div>
          <div>
            <button class="search-btn" @click="searchApply">搜索</button>
          </div>
        </div>
        <div class="result-container">
          <table>
            <thead>
              <tr>
                <th scope="col" style="width: 90px">课题ID</th>
                <th scope="col" style="width: 350px">课题名称</th>
                <th scope="col" style="width: 120px">状态</th>
                 <th scope="col" style="width: 220px">文件</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="classResult.length == 0 && this.hasSearch">
                <td colspan="3">未查询到结果</td>
              </tr>
              <tr v-for="item in classResult" :key="item.id">
                <th scope="row">{{ item.topicId }}</th>
                <td>{{ item.topic }}</td>
                <td >
                <div class="result-status">
                    <span
                    :class="
                      item.topicStatus == '待审核'
                        ? 'orange item'
                        : item.topicStatus == '审核通过'
                        ? 'green item'
                        : 'red item'
                    "
                  ></span>
                  {{ item.topicStatus }}
                </div>
                </td>
                <td>
                  <p class="file-name" v-for="file in item.fileReqs" :key="file.path" @click="downloadFile(file)">
                    {{ file.fileName }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="contact">
            <span class="required">*</span>
            <span class="tip">如遇问题请联系我们topic.service@c2m-mom.com</span>
          </div>
        </div>
      </div>
      <!-- <slot name="footer">
        <div class="modal-footer">
          <button @click="closeModal" class="modal-button">关闭</button>
        </div>
      </slot> -->
    </div>
    <Toast
      v-if="show"
      :message="messageContent"
      :type="messageType"
      @close="show = false"
    ></Toast>
  </div>
</template>

<script>
import Toast from '@/components/Toast'
export default {
  components: {
    Toast,
  },
  data: () => {
    return {
      searchValue: '',
      classResult: [],
      show: false,
      hasSearch: false,
    }
  },
  methods: {
    closeModal () {
      this.$emit('close', false)
    },
    openToast (text, type) {
      this.show = true
      this.messageContent = text
      this.messageType = type
    },
    searchApply () {
      if (!this.searchValue) return this.openToast('请输入课题ID', 'fail')
      fetch(
        `/iot/partner/getByTopicId?topicId=${this.searchValue}`,
        {
          method: 'get',

          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
        .then((response) => response.json())
        .then((data) => {
          this.hasSearch = true
          if (data.success) {
            this.classResult = data?.data ? [data.data] : []
          }
        })
        .catch(() => this.openToast('查询失败', 'fail'))
    },
    downloadFile (file) {
      fetch(`/iot/partner/fileDownload?fileName=${file.fileName}&path=${file.path}`, {
        method: 'get',
        responseType: 'blob',
      }).then(res => {
        return res.blob()
      }).then((data) => {
        const url = window.URL.createObjectURL(data)
        const a = document.createElement('a')
        a.href = url
        a.download = file.fileName // 设置下载文件名
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url) // 释放对象 URL
      })
    },
  },
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.modal {
  background: white;

  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 620px;
  width: 100%;
}

.modal-header {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: 0px 0px 12px 0px #002f8a0f;
  line-height: 60px;
}

.modal-title {
  font-size: 18px;
  font-weight: bold;

  width: 100%;
}

.modal-close {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.modal-body {
  padding: 20px;
  margin-bottom: 10px;
}

.modal-footer {
  text-align: right;
}

.modal-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}
.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.required {
  color: red;
  margin-right: 5px;
}
.form-item {
  display: flex;
  align-items: center;
  padding: 10px 40px;

  .label {
    width: 120px;
    text-align: left;
    font-size: 16px;
  }
  input {
    width: 430px;
    background-color: #f2f5f7;
    height: 36px;
    border: none;
    text-indent: 20px;
  }
}
.result-container {
  margin-top: 40px;
  .result-status{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .item {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  .green {
    background-color: green;
  }
  .orange {
    background-color: orange;
  }
  .red {
    background-color: red;
  }
}
.search-btn {
  width: 180px;
  height: 44px;
  color: #fff;
  background-color: #0057ff;
  text-align: center;
  line-height: 44px;
  border: none;
  margin-top: 20px;
  cursor: pointer;
}
table {
  border-collapse: collapse;
  /* border: 2px solid rgb(140 140 140); */
  font-family: sans-serif;
  font-size: 0.8rem;
  letter-spacing: 1px;
}

thead,
tfoot {
  background-color: #f2f5f7;
  height: 48px;
  font-size: 14px;
  font-weight: 500;
}

th,
td {
  /* border: 1px solid rgb(160 160 160); */
  padding: 8px 10px;
}

td:last-of-type {
  text-align: center;
}

tbody > tr:nth-of-type(even) {
  background-color: rgb(237 238 242);
}

tfoot th {
  text-align: right;
}

tfoot td {
  font-weight: bold;
}
.tip {
  font-size: 14px;
  font-weight: 300;
}
.contact {
  margin-top: 10px;
}
.file-name{
  color:#007bff;
  cursor: pointer;
}
</style>
