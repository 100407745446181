<template>
  <div class="wrapper">
    <div class="container">
      <p class="title">本期课题揭榜</p>

    </div>

     <div class="result-wrapper">
       <div class="result-list">
        <div class="result-item" v-for="item in mockData" :key="item.id">
          <div class="top">
            <div class="left">
              <p class="name">{{ item.applyName}}</p>
              <p class="school">{{ item.institutionName
 }}</p>
            </div>
            <!-- <img class="right" :src="imgs[0]" alt="" /> -->
          </div>
          <div class="bottom">
            <div class="result-title">{{ item.topic}}</div>
            <div class="result-content">
              <div class="left">
                <p class="date-title">课题揭榜时间</p>
                <p class="date">{{ item.approvalTime.toString().slice(0, 10)
}}</p>
              </div>
              <!-- <div class="right">一般课题</div> -->
            </div>
          </div>
        </div>

      </div>
     </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      imgs: [
        require('@/assets/class/result1.png'),
        require('@/assets/class/result2.png'),
        require('@/assets/class/result3.png'),
      ],
      mockData: [{
        title: '工业元宇宙数字底座体系化关键技术',
        name: '张桦',
        school: '华中科技大学',
        date: '2024-05-29',
      }],
    }
  },
  methods: {
    getTopics () {
      fetch('/iot/partner/topicList', {
        method: 'get',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then((response) => response.json()).then(data => {
        this.mockData = data?.data || []
      })
    },
  },
  mounted () {
    this.getTopics()
  },
}

</script>
<style lang="less" scoped>
@import "~@/style/global";

.wrapper {
  .g_center_wrapper();
  background: #d4e0fa;
  height: 640px;
}

.container {
  .g_center();
  margin: 62px auto 15px auto;
  overflow: hidden;
}

.title {
  margin: 90px 0 70px 0;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 45px;
  text-align: center;
  color: #000000;
}

.description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  text-indent: 2em;
  text-transform: uppercase;
  color: #3e3e3e;
}
.result-wrapper{
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
}
.result-list {
  display: flex;
  align-items: center;
  .result-item {
    width: 540px;
    height: 360px;
    padding:0 25px;
    .top {
      width: 100%;
      height: 176px;
      background: url("../../../../assets/class/texture.png") no-repeat;
      background-position: right bottom;
      background-size: 540px 106px;
      position: relative;
      .left {
        position: absolute;
        bottom: 0;
        color: #fff;
        padding-left: 34px;
        font-weight: 400;
        padding-bottom: 20px;
        .name {
          font-size: 24px;
          line-height: 34px;
        }
        .school {
          font-size: 18px;
          line-height: 26px;
        }
      }
      .right {
        bottom: 0;
        right: 50px;
        position: absolute;
      }
    }
    .bottom {
      box-sizing: border-box;
      padding: 30px ;
      height: 184px;
      background: #fff;
      .result-title {
        font-size: 24px;
        font-weight: 600;
        line-height: 34px;
        min-height:80px;
        width:384px

      }
      .result-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top:10px;

        .date-title {
          font-size: 18px;
          font-weight: 400;
          line-height: 26px;
          text-align: left;
          color: #838383;
        }
        .date {
          font-family: PingFang SC;
          font-size: 24px;
          font-weight: 500;
          line-height: 34px;
          color: #de7a40;
        }
      }
      .right{
width: 76px;
height: 30px;
border-radius: 8px;
background:#CFDFFF;
color:#0057FF;
text-align:center;
line-height:30px;
font-size:14px;
      }
    }
  }
}
</style>
